import { createGlobalStyle } from 'styled-components';
import './flaticon.css';

export const ResetCSS = createGlobalStyle`
  ::selection {
    background: #333333;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .reuseModalHolder {
    padding: 0 !important;
    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.9) !important;
      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
  }

  button.modalCloseBtn {
    position: fixed !important;
    z-index: 999991 !important;
    background-color: transparent !important;
    top: 10px !important;
    right: 10px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    padding: 0 !important;
    span.btn-icon {
      font-size: 22px !important;
      transform: rotate(45deg) !important;
    }

    &.alt {
      border-radius: 50% !important;
      z-index: 999999 !important;
      padding: 0 !important;
      transition: all 0.3s ease !important;
      top: 25px !important;
      right: 30px !important;
      min-width: 40px !important;
      min-height: 40px !important;

      span.btn-icon {
        font-size: 20px !important;
      }

      &:hover {
        opacity: 0.88 !important;
      }
    }
  }
  table{
    min-width: 80%;
    margin: 0 auto 40px auto;
  }
  table td{
    line-height: 1.4rem;
    padding: 10px;
    display: inline-block;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  table tr{
    display: flex;
    border-bottom: 1px solid #ddd;
  }
  table td:first-of-type{
    width: 20%;
    font-weight: 600;
  }
  table td:last-of-type{
    width: 80%;
  }
  table tr:last-of-type{
    border-bottom: none;
  }
  table th{
    padding: 10px;
  }

  table.tableStyle2 td:last-of-type,
  table.tableStyle2 td:first-of-type,
  table.tableStyle2 th:last-of-type,
  table.tableStyle2 th:first-of-type
  {
    width: 50%;
    justify-content: center;
  }
  table.tableStyle2 td:last-of-type,
  table.tableStyle2 td:first-of-type{
    font-weight: normal;
    text-align: center;
  }

  table.tableStyle2 td:first-of-type{
    font-weight: normal;
    text-align: center;
    border-right: 1px solid #ddd;
  }
  table.tableStyle2 tr:last-of-type{
    border-bottom: 1px solid #ddd;
  }
  .team__member{
    display: flex;
  }
  .team__member > div:first-of-type{
    width: 35%;
  }
  .team__member > div:last-of-type{
    width: 65%;
    margin-left: 35px;
  }
  @media(max-width: 639px){
    .team__member > div:first-of-type{
      width: 100%;
      padding: 0 30px;
    }
    .team__member > div:last-of-type{
      width: 100%;
      margin-left: 0;
    }
    .team__member{
      flex-direction: column;
    }
  }
`;
